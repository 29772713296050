import { WatchOptions } from 'vue';

export default {
  biogasanlageItemsDataForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  versicherungsortItemsForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request', true);
      }
    },
    deep: true,
  },
  selbstbeteiligungsvariantenTarifForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'tarif');
      }
    },
    deep: true,
  },
  selbstbeteiligungsvariantenVariant1ForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant1');
      }
    },
    deep: true,
  },
  selbstbeteiligungsvariantenVariant2ForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant2');
      }
    },
    deep: true,
  },

  selbstbeteiligungsvariantenVariant3ForPrice: {
    handler(newVal: any, oldVal: any) {
      // eslint-disable-next-line no-underscore-dangle
      if (!(this as any)._resettingWatcher && !(this as any)._startingWatcher) {
        this.$emit('change-data-for-price-request-for-variant', true, 'variant3');
      }
    },
    deep: true,
  },


} as Record<string, any>;

